import React from 'react';

const Profile = () => {
    return (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <h1>Update Profile</h1>
            <p>Here you can update your profile information.</p>
        </div>
    );
};

export default Profile;
