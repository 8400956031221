// src/components/JobsBot.js

import React from 'react';
// Import AdminNavbar component
import AdminNavbar from './components/AdminNavbar'; // Adjust the path based on where AdminNavbar is located
import jobsBotImage from './assets/images/jobsbot-med.png'; // Adjust path based on your directory structure

const JobsBot = () => {
    // Sample FAQ data
    const faqs = [
        { question: "What is Jobs Bot?", answer: "Jobs Bot is an automated service that helps you find job opportunities tailored to your preferences." },
        { question: "How do I use Jobs Bot?", answer: "Simply enter your job preferences, and the bot will provide you with a list of suitable job openings." },
        { question: "Is there a cost to use Jobs Bot?", answer: "No, Jobs Bot is completely free to use." },
        { question: "How can I provide feedback?", answer: "You can provide feedback through our contact form available on the website." },
    ];

    return (
        <div className="job-admin-container">
            <AdminNavbar /> {/* Include the AdminNavbar component */}

            {/* Jobs Bot Card */}
            <div className="job-admin-card" style={{ marginBottom: '10px' }}>
                <h1>Welcome to Jobs Bot</h1>
            </div>

            {/* Add the image below the welcome message */}
            <div className="job-admin-card">
                <img className="job-admin-image" src={jobsBotImage} alt="Jobs Bot" />
            </div>

            {/* FAQ Card */}
            <div className="job-admin-card">
                <h2>Frequently Asked Questions</h2>
                {faqs.map((faq, index) => (
                    <div key={index}>
                        <h3 className="faq-question">{faq.question}</h3>
                        <p className="faq-answer">{faq.answer}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default JobsBot;
