import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './Login';
import Welcome from './Welcome'; // Import the Welcome component
import SavedJobs from './SavedJobs'; // Import the SavedJobs component
import SearchJobs from './SearchJobs'; // Import the SearchJobs component
import Profile from './Profile'; // Import the Profile component
import JobsBot from './JobsBot'; // Import the JobsBot component
import JobAdmin from './JobAdmin'; // Import the JobAdmin component
import '@fortawesome/fontawesome-free/css/all.css'; // Import Font Awesome 5
import './App.css';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleLogin = () => {
    setIsAuthenticated(true); // Update login state on successful login
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Login route */}
          <Route path="/login" element={<Login onLogin={handleLogin} />} />

          {/* Welcome route, redirect to login if not authenticated */}
          <Route
            path="/welcome"
            element={isAuthenticated ? <Welcome /> : <Navigate to="/login" />}
          />

          {/* Additional routes */}
          <Route path="/saved-jobs" element={isAuthenticated ? <SavedJobs /> : <Navigate to="/login" />} />
          <Route path="/search-jobs" element={isAuthenticated ? <SearchJobs /> : <Navigate to="/login" />} />
          <Route path="/profile" element={isAuthenticated ? <Profile /> : <Navigate to="/login" />} />
          <Route path="/jobs-bot" element={isAuthenticated ? <JobsBot /> : <Navigate to="/login" />} />
          <Route path="/job-admin" element={isAuthenticated ? <JobAdmin /> : <Navigate to="/login" />} />

          {/* Default route, redirect to login */}
          <Route path="/" element={<Navigate to="/login" />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
