import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Welcome.css';
import logo from './assets/images/logo.png'; // Import the logo image

const Welcome = () => {
    const navigate = useNavigate();

    return (
        <div className="welcome-container">
            {/* Navigation Section at the Top */}
            <div className="welcome-navigation">
                <img src={logo} alt="Logo" className="nav-logo" /> {/* Logo on the left */}
                <button onClick={() => navigate('/search-jobs')} className="nav-button">
                    <i className="fas fa-search" aria-hidden="true"></i> Search Jobs
                </button>
                <button onClick={() => navigate('/saved-jobs')} className="nav-button">
                    <i className="fas fa-bookmark" aria-hidden="true"></i> View Saved Jobs
                </button>
                <button onClick={() => navigate('/profile')} className="nav-button">
                    <i className="fas fa-user" aria-hidden="true"></i> Update Profile
                </button>
                <button onClick={() => navigate('/jobs-bot')} className="nav-button">
                    <i className="fas fa-robot" aria-hidden="true"></i> Jobs Bot
                </button>
                <button onClick={() => navigate('/job-admin')} className="nav-button">
                    <i className="fas fa-cog" aria-hidden="true"></i> Job Admin
                </button>
            </div>

            {/* Welcome Card Below the Navigation */}
            <div className="welcome-card">
                <h1>Welcome!</h1>
                <p>You have successfully logged in.</p>
                {/* Buttons inside the Welcome Card with separate divs */}
                <div style={{ paddingBottom: '5px' }}>
                    <button onClick={() => navigate('/search-jobs')} className="welcome-button">
                        <i className="fas fa-search" aria-hidden="true"></i> Search Jobs
                    </button>
                </div>
                <div style={{ paddingBottom: '5px' }}>
                    <button onClick={() => navigate('/saved-jobs')} className="welcome-button">
                        <i className="fas fa-bookmark" aria-hidden="true"></i> View Saved Jobs
                    </button>
                </div>
                <div style={{ paddingBottom: '5px' }}>
                    <button onClick={() => navigate('/profile')} className="welcome-button">
                        <i className="fas fa-user" aria-hidden="true"></i> Update Profile
                    </button>
                </div>
                <div style={{ paddingBottom: '5px' }}>
                    <button onClick={() => navigate('/jobs-bot')} className="welcome-button">
                        <i className="fas fa-robot" aria-hidden="true"></i> Jobs Bot
                    </button>
                </div>
                <div style={{ paddingBottom: '5px' }}>
                    <button onClick={() => navigate('/job-admin')} className="welcome-button">
                        <i className="fas fa-cog" aria-hidden="true"></i> Job Admin
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Welcome;
