import React from 'react';

const SavedJobs = () => {
    return (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <h1>Saved Jobs</h1>
            <p>Here you can view your saved jobs.</p>
        </div>
    );
};

export default SavedJobs;
