import React from 'react';

const SearchJobs = () => {
    return (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <h1>Search Jobs</h1>
            <p>Here you can search for jobs.</p>
        </div>
    );
};

export default SearchJobs;
