// Import React and necessary hooks
import React, { useState } from 'react';

// Import Google Maps components
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

import './JobAdmin.css'; // Existing CSS import
import logo from './assets/images/logo.png'; // Import the logo

// Import AdminNavbar component
import AdminNavbar from './components/AdminNavbar'; // Adjust the path based on where AdminNavbar is located

// Define the JobAdmin component
const JobAdmin = () => {
    const [searchQuery, setSearchQuery] = useState('');

    // Define job data including location, employer, role, and application info
    const [jobs, setJobs] = useState([
        { id: 1, location: "123 Ocean Road, Brighton, BN1 4NQ", logo: logo, employer: "Tech Innovations", hours: "Full-Time", type: "Permanent", role: "Software Engineer", apply: "Send CV to careers@techinnovations.co.uk", lat: 50.8233, lng: -0.1406 },
        { id: 2, location: "456 King’s Road, Brighton, BN1 2NX", logo: logo, employer: "Brighton Marketing", hours: "Part-Time", type: "Contract", role: "Digital Marketing Specialist", apply: "Apply via our website", lat: 50.8220, lng: -0.1390 },
        { id: 3, location: "789 North Street, Brighton, BN1 3DG", logo: logo, employer: "Design Studio", hours: "Full-Time", type: "Internship", role: "Graphic Designer", apply: "Email portfolio to hello@designstudio.co.uk", lat: 50.8235, lng: -0.1395 },
        { id: 4, location: "101 Hove Street, Brighton, BN3 2AD", logo: logo, employer: "Finance Corp", hours: "Full-Time", type: "Permanent", role: "Financial Analyst", apply: "Visit our LinkedIn page", lat: 50.8260, lng: -0.1440 },
        { id: 5, location: "202 Shoreham Road, Brighton, BN1 8ED", logo: logo, employer: "Health Solutions", hours: "Contract", type: "Temporary", role: "Healthcare Assistant", apply: "Contact us at apply@healthsolutions.co.uk", lat: 50.8265, lng: -0.1435 },
    ]);

    // Filter jobs based on search query
    const filteredJobs = jobs.filter(job =>
        job.location.toLowerCase().includes(searchQuery.toLowerCase()) ||
        job.employer.toLowerCase().includes(searchQuery.toLowerCase()) ||
        job.hours.toLowerCase().includes(searchQuery.toLowerCase()) ||
        job.type.toLowerCase().includes(searchQuery.toLowerCase()) ||
        job.role.toLowerCase().includes(searchQuery.toLowerCase()) ||
        job.apply.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="job-admin-container">
            {/* Include the AdminNavbar component */}
            <AdminNavbar />

            {/* Search Input */}
            <div className="search-container">
                <input
                    type="text"
                    placeholder="Filter list by location, employer, type, etc."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </div>

            {/* Job Table */}
            <div className="job-admin-card">
                <h1>Job Administration</h1>
                <table className="job-table">
                    <thead>
                        <tr>
                            <th>Logo</th>
                            <th>Location</th>
                            <th>Employer</th>
                            <th>Hours</th>
                            <th>Type</th>
                            <th>Role</th>
                            <th>How to Apply</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredJobs.map((job) => (
                            <tr key={job.id}>
                                <td>
                                    <img src={job.logo} alt={`${job.employer} logo`} style={{ width: "50px", height: "50px" }} />
                                </td>
                                <td>{job.location}</td>
                                <td>{job.employer}</td>
                                <td>{job.hours}</td>
                                <td>{job.type}</td>
                                <td>{job.role}</td>
                                <td>{job.apply}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {/* Google Maps Section */}
                <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
                    <div className="map-container">
                        <GoogleMap
                            mapContainerStyle={{ height: "400px", width: "100%" }}
                            center={{ lat: 50.8233, lng: -0.1406 }} // Center of Brighton
                            zoom={14}
                        >
                            {filteredJobs.map((job) => (
                                <Marker
                                    key={job.id}
                                    position={{ lat: job.lat, lng: job.lng }}
                                    title={job.employer}
                                />
                            ))}
                        </GoogleMap>
                    </div>
                </LoadScript>
            </div>
        </div>
    );
};

export default JobAdmin;
