// src/components/AdminNavbar.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import './AdminNavbar.css';  // Ensure to keep your existing CSS import
import logo from '../assets/images/logo.png';  // Adjust the path if necessary
import '@fortawesome/fontawesome-free/css/all.css'; // Import Font Awesome icons

const AdminNavbar = () => {
  const navigate = useNavigate();

  return (
    <div className="welcome-container"> {/* Use the same container class as Welcome */}
      {/* Navigation Section at the Top */}
      <div className="welcome-navigation">
        <img
          src={logo}
          alt="Logo"
          className="nav-logo"
          onClick={() => navigate('/welcome')} // Navigate to /welcome on logo click
          style={{ cursor: 'pointer' }} // Change cursor to pointer for better UX
        /> {/* Logo on the left */}
        <button onClick={() => navigate('/search-jobs')} className="nav-button">
          <i className="fas fa-search" aria-hidden="true"></i> Search Jobs
        </button>
        <button onClick={() => navigate('/saved-jobs')} className="nav-button">
          <i className="fas fa-bookmark" aria-hidden="true"></i> View Saved Jobs
        </button>
        <button onClick={() => navigate('/profile')} className="nav-button">
          <i className="fas fa-user" aria-hidden="true"></i> Update Profile
        </button>
        <button onClick={() => navigate('/jobs-bot')} className="nav-button">
          <i className="fas fa-robot" aria-hidden="true"></i> Jobs Bot
        </button>
        <button onClick={() => navigate('/job-admin')} className="nav-button">
          <i className="fas fa-cog" aria-hidden="true"></i> Job Admin
        </button>
      </div>
    </div>
  );
};

export default AdminNavbar;
