import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './assets/images/logo.png'; // Ensure this path is correct
import './Login.css'; // Import the CSS file

const Login = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate(); // Use the useNavigate hook

    const handleSubmit = (e) => {
        e.preventDefault();

        //const storedPassword = process.env.REACT_APP_PASSWORD;
        console.log('Stored Password (from env):', storedPassword);
        const storedPassword = "paloma";

        if (username === 'admin' && password === storedPassword) {
            console.log('Login successful! Redirecting...');
            onLogin(); // Call onLogin to trigger the state change
            setError(''); // Clear any error messages

            // Programmatically navigate to the /welcome page after login success
            navigate('/welcome');
        } else {
            setError('Username or password is incorrect.');
            console.log('Login failed. Incorrect username or password.');
        }
    };

    return (
        <div className="login-container">
            <div className="login-card">
                <h2>Login</h2>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label style={{ display: 'block', textAlign: 'left', marginBottom: '5px' }}>
                            Username:
                        </label>
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                            className="input"
                        />
                    </div>
                    <div>
                        <label style={{ display: 'block', textAlign: 'left', marginBottom: '5px' }}>
                            Password:
                        </label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            className="input"
                        />
                    </div>
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    <button type="submit" className="button">Login</button>
                </form>
            </div>
        </div>
    );
};

export default Login;
